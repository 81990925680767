<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:1050px">
            <div slot="header">
            <div class="row">
              <div class="col-md-8">
                <strong>Mão de obra</strong>
              </div>
              <div class="col-md-4" style="text-align:right;">
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "maodeobra.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
<b-tabs content-class="mt-3" v-model="ativa">
  <b-tab title="Ativos" active>
    <div class="table-scroll">
        <table class="table table-hover table-sm">
            <thead>
            <tr>
                <th class="fixo" :style="colunaFixa1"></th>
                <th data-v-step="1" class="fixo" :style="colunaFixa2">Nome</th>
                <th class="fixo" :style="colunaFixa3">Função</th>
                <th style="width:80px;">Cód.</th>
                <th style="width:140px;">Salário</th>
                <th style="width:100px;">Tipo</th>
                
                <th style="width:190px;" v-html="encargosTitle"></th>
                <th style="width:120px;">Vale Transp.</th>
                <th style="width:120px;">Vale Aliment.</th>
                <th style="width:120px;">Convênio</th>
                <th style="width:120px;">Outros 1</th>
                <th style="width:120px;">Outros 2</th>

                <th style="width:160px;">Total</th>
                <th style="width:120px;">Qt</th>
                <th style="width:120px;">Ócio</th>
                <th style="width:120px;">Custo/H</th>
                <th style="width:120px;">Custo/Min.</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(maodeobraItem, index) in mao" :key="index">
                    <th data-title="Em uso?" :style="colunaFixa1">
                      <b-form-checkbox size="sm"
                        v-model="mao[index].mao_active" 
                        switch 
                        @change.native="editar(index)"
                      ></b-form-checkbox>
                    </th>
                    <th data-title="Nome" :style="colunaFixa2">
                      <b-form-input class="verde" @blur.native="editar(index, true)" v-model="mao[index].mao_name" size="sm"></b-form-input>
                    </th>
                    <th data-title="Função" :style="colunaFixa3">
                      <b-form-input class="verde" @blur.native="editar(index, true)" v-model="mao[index].mao_funcao" size="sm"></b-form-input>
                    </th>
                    <td data-title="Código">
                      <b-form-input class="verde" @blur.native="editar(index, true)" v-model="mao[index].mao_codigo" size="sm"></b-form-input>
                    </td>
                    <td data-v-step="5" data-title="Salário">
                      <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_salario" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Tipo">
                      <b-form-select style="background-image: none;" @change="calculaTotal(false, index)" class="verde" :options="optionsEncTipo" v-model="mao[index].mao_tipo" size="sm"></b-form-select>
                    </td>

                    <td data-title="Encargos">
                      <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" :options="dinheiro" readonly v-model="mao[index].mao_enc_total" size="sm"></vue-autonumeric>
                      
                    </td>
                    <td data-title="Vale Transporte">
                        <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale1" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Vale Alimentação">
                        <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale2" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Convênio">
                        <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale3" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Outros 1">
                        <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale4" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Outros 2">
                        <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control verde direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_vale5" size="sm"></vue-autonumeric>
                    </td>
                        

                    <td data-title="Total">
                      <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_total" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Qt">
                      <vue-autonumeric class="form-control input-sm verde centro" @blur.native="calculaTotal(false, index)" style="font-size:10px;" v-model="mao[index].mao_qt" :options="decimal" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Ócio'">
                      <vue-autonumeric @blur.native="calculaTotal(false, index)" class="form-control input-sm verde centro" style="font-size:10px;" :options="perc" v-model="mao[index].mao_ocio" size="sm"></vue-autonumeric>
                    </td>
                    <td data-title="Custo Hora">
                      <vue-autonumeric class="form-control input-sm amarelo centro" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_custohora" readonly></vue-autonumeric>
                    </td>
                    <td data-title="Custo Minuto">
                      <vue-autonumeric class="form-control input-sm amarelo direita" style="font-size:10px;" :options="dinheiro" v-model="mao[index].mao_customin" readonly></vue-autonumeric>
                    </td>
                    <td data-title="Em uso?">
                        <div v-if="emUso.indexOf(mao[index].mao_ID) >= 0">🔴</div>
                        <div v-else>⚪️</div>
                    </td>
                </tr>
                <tr id="adicionando">
                    <th :style="colunaFixa1"></th>
                    <th data-title="Nome" data-v-step="2" :style="colunaFixa2"><b-form-input placeholder="Nome" v-model="maoName" size="sm"></b-form-input></th>
                    <th data-title="Função" :style="colunaFixa3"><b-form-input placeholder="Função" v-model="maoFuncao" size="sm"></b-form-input></th>
                    <td data-title="Código"><b-form-input placeholder="Cód." v-model="maoCodigo" size="sm"></b-form-input></td>
                    <td data-title="Salário" data-v-step="3"><vue-autonumeric class="form-control input-sm direita" style="font-size:10px;" :options="dinheiro" v-model="maoSalario"></vue-autonumeric></td>
                    <td data-v-step="4" colspan="12"><button class="btn btn-success btn-sm" :disabled="adicionou" @click="adicionar()">Adicionar</button></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
     </b-tab>
<b-tab title="Inativos">

<div class="table-responsive">
        <table class="table table-hover table-sm">
            <thead>
            <tr>
                <th></th>
                <th>Nome</th>
                <th>Função</th>
                <th style="width:80px;">Cód.</th>
                <th style="width:140px;">Salário</th>
                <th style="width:100px;">Tipo</th>
                
                <th style="width:120px;">Vale Transp.</th>
                <th style="width:120px;">Vale Aliment.</th>
                <th style="width:120px;">Convênio</th>
                <th style="width:120px;">Outros 1</th>
                <th style="width:120px;">Outros 2</th>

                <th style="width:140px;">Total</th>
                <th style="width:80px;">Qt</th>
                <th style="width:80px;">Ócio</th>
                <th style="width:100px;">Custo/H</th>
                <th style="width:100px;">Custo/Min.</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(maodeobraItem2, index) in maooff" :key="index">
                    <td>
                      <b-form-checkbox 
                        v-model="maooff[index].mao_active" 
                        switch 
                        @change.native="reativarItem(maooff[index].mao_ID)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input class="amarelo" readonly v-model="maooff[index].mao_name" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="amarelo" readonly v-model="maooff[index].mao_funcao" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-input class="amarelo" readonly v-model="maooff[index].mao_codigo" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control amarelo direita" style="font-size:10px;" readonly :options="dinheiro" v-model="maooff[index].mao_salario" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-select style="background-image: none;" class="amarelo" readonly :options="optionsEncTipo" v-model="maooff[index].mao_tipo" size="sm"></b-form-select>
                    </td>

                    <td>
                        <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_vale1" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                        <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_vale2" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                        <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_vale3" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                        <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_vale4" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                        <vue-autonumeric class="form-control amarelo direita" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_vale5" size="sm"></vue-autonumeric>
                    </td>
                        

                    <td>
                      <vue-autonumeric class="form-control amarelo" readonly style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_total" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm verde centro" readonly style="font-size:10px;" :options="decimal" v-model="maooff[index].mao_qt" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm amarelo centro" readonly style="font-size:10px;" :options="perc" v-model="maooff[index].mao_ocio" size="sm"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm amarelo centro" style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_custohora" readonly></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm amarelo direita" style="font-size:10px;" :options="dinheiro" v-model="maooff[index].mao_customin" readonly></vue-autonumeric>
                    </td>
                    <td>
                        <div v-if="emUso.indexOf(maooff[index].mao_ID) >= 0">🔴</div>
                        <div v-else><b-button
                          @click="removerItem(index)" 
                          size="sm" 
                          style="background-color: white;"
                        >❌</b-button></div>
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>

</b-tab>
</b-tabs>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Mão de Obra">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/4-JyRPcvIIU?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
        <a v-if="isMobile()" href="#adicionando" class="btnfloat">
      <CIcon name="cil-plus" class="my-btnfloat" />
    </a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Mão de obra',
          },
          content: `Aqui você cadastra a mão de obra envolvida na geração de seus produtos`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar um novo, digite o nome ou descrição aqui (Ex: Fulano)',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Digite o salário.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Clique aqui para adicionar e pronto!',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Essa tabela é dinâmica, ou seja, na medida que você altera algum item, ela salva automaticamente.',
          params: {
            placement: 'bottom'
          }
        }
      ],
      colunaFixa1: 'width:10px; left: 0;',
      colunaFixa2: 'width:200px; left: 43px;',
      colunaFixa3: 'width:320px; left: 135px;',
      ativa: 0,
      adicionou: false,
      encargosTitle: 'Encargos',
      maodeobraItem: [],
      maooff: [],
      emUso: [],
      matExcel: [],
      sessao: '',
      loading: false,
      mao: [],
      maoCodigo: '',
      maoName: '',
      maoFuncao: '',
      maoSalario: 0,
      maoActive: 1,
      optionsEnc: [],
      optionsEncTipo: [
        {value: 'mes', text: 'Mensal'},
        {value: 'zero', text: 'Sem Encargo'}
      ],
      encargos: [],
      maoval: [],
      maovalh: [],
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      perc: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  ready: function () {
    if (window.innerWidth <= 600) {
      this.colunaFixa1 = ''
      this.colunaFixa2 = ''
      this.colunaFixa3 = ''
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('TABELAS_MAODEOBRA'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        // Atualiza lista com base no encargo ativo
        this.campos('pro_Encargo', 'enc_active = 1').then(
          (a) => {
            if (typeof a === 'undefined' || a.length === 0) {
              this.colunaFixa1 = 'width:10px; left: 0;'
              this.colunaFixa2 = 'width:130px; left: 8px;'
              this.colunaFixa3 = 'width:130px; left: 92px;'
              this.$swal('Encargos não encontrados', 'Acesse o menu CONFIGURAÇÕES > ENCARGOS antes de acessar aqui.', 'error')
              this.loading = false
              return false
            }
            this.encargos = a
            this.encargosTitle = 'Encargos (' + a.enc_total_mes + '%)'
            this.atualizar('pro_Maodeobra', {resource: [ {enc_ID: this.encargos.enc_ID} ]}, 'mao_active = 1', '', '1').then(
              (r) => {
                this.calculaTotal(true)
                this.recarregar()
              },
              (r) => {
                this.recarregar()
              }
            )
          },
          (a) => {
            this.$swal('Encargos não encontrados', 'Acesse o menu CONFIGURAÇÕES > ENCARGOS antes de acessar aqui.', 'error')
            this.loading = false
            return false
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
      if (window.innerWidth <= 600) {
        this.colunaFixa1 = ''
        this.colunaFixa2 = ''
        this.colunaFixa3 = ''
      }
      this.optionsEnc = []
      this.mao = []
      this.maooff = []
      this.maoval = []
      this.maovalh = []
      this.listar('pro_Maodeobra', 'mao_funcao, mao_name', '').then(
        (response) => {
          if (typeof response === 'undefined' || response.length === 0) {
            // Ajusta a tabela para quando não tem registros
            if (window.innerWidth > 600) {
              this.colunaFixa1 = 'width:10px; left: 0;'
              this.colunaFixa2 = 'width:130px; left: 8px;'
              this.colunaFixa3 = 'width:130px; left: 92px;'
            } else {
              this.colunaFixa1 = ''
              this.colunaFixa2 = ''
              this.colunaFixa3 = ''
            }
          }
          // this.mao = response
          this.listar('pro_Encargo', 'enc_ID', 'enc_active >= 0').then(
              (r) => {
                // this.optionsEnc.push({ value: 0, text: 'Nenhum', disabled: 0 })
                for(var i = 0; i < r.length; i++) {
                    this.optionsEnc.push({ value: r[i].enc_ID, text: r[i].enc_name + ' (' + r[i].enc_total_mes + '% mês / ' + r[i].enc_total_hora + '% hora)', disabled: !r[i].enc_active })
                    this.maoval[r[i].enc_ID] = r[i].enc_total_mes
                    this.maovalh[r[i].enc_ID] = r[i].enc_total_hora
                }
                var prom = []
                for (var z = 0; z < response.length; z++) {
                  if (response[z].mao_active) {
                    this.mao.push(response[z])
                  } else {
                    this.maooff.push(response[z])
                  }
                  this.calculaTotal()
                  this.matExcel.push({
                    Ativo: response[z].mao_active ? 'Sim' : 'Não',
                    Nome: response[z].mao_name,
                    Funcao: response[z].mao_funcao,
                    Cod: response[z].mao_codigo,
                    Salario: response[z].mao_salario,
                    Tipo: response[z].mao_tipo,
                    Encargos: response[z].mao_enc_total,
                    Vale_Transp: response[z].mao_vale1,
                    Vale_Alim: response[z].mao_vale2,
                    Convenio: response[z].mao_vale3,
                    Outros_1: response[z].mao_vale4,
                    Outros_2: response[z].mao_vale5,
                    Total: response[z].mao_total,
                    Qt: response[z].mao_qt,
                    Ocio: response[z].mao_ocio,
                    Custo_H: response[z].mao_custohora,
                    Custo_Min: response[z].mao_customin
                  })
                  prom.push(this.emUsoCheck(response[z].mao_ID))
                }
                Promise.all(prom).then(
                  (p) => {
                    this.emUso = p
                    this.calculaTotal()
                    this.loading = false
                  },
                  (p) => {
                    this.calculaTotal()
                    this.loading = false
                  }
                )
              },
              (r) => {
                  this.$notify({
                      type: 'danger',
                      title: 'Dados básicos necessários',
                      text: 'Cadastre encargos antes.'
                  })
                  this.loading = false
              }
          )
        }, (response) => {
          if (window.innerWidth > 600) {
            // Ajusta a tabela para quando não tem registros
            this.colunaFixa1 = 'width:10px; left: 0;'
            this.colunaFixa2 = 'width:130px; left: 8px;'
            this.colunaFixa3 = 'width:130px; left: 92px;'
          } else {
            this.colunaFixa1 = ''
            this.colunaFixa2 = ''
            this.colunaFixa3 = ''
          }
          this.loading = false
        })
    },
    emUsoCheck: function (i) {
      return this.campo('pro_Produto_Maodeobra', 'mao_ID', '(mao_ID = ' + String(i) + ')').then(
        (c) => {
          return (c.length > 0 ? 0 : i)
        },
        (c) => {
          return 0
        }
      )
    },
    removerItem: function (i) {
      if (confirm('Remover esse item definitivamente?')) {
        this.remover('pro_Maodeobra', 'mao_ID = ' + this.maooff[i].mao_ID).then(
          (t) => {
            this.recarregar()
          },
          (t) => {
            this.recarregar()
          }
        )
      } else {
        return false
      }
    },
    reativarItem: function (i) {
      this.atualizar('pro_Maodeobra', {resource: [ {mao_active: 1 } ]}, 'mao_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.recarregar()
        },
        (r) => {
          this.recarregar()
        }
      ) 
    },
    adicionar: function () {
      this.adicionou = true
      if (this.maoFuncao === '' || this.maoCodigo === '' || this.maoSalario === 0) {
        this.$swal('Preencha os campos obrigatórios: função, código e salário')
        this.adicionou = false
        return false
      }
      // Verifica se já existe
      this.campo('pro_Maodeobra', 'mao_ID', '(mao_codigo = ' + this.maoCodigo + ')').then(
        (response) => {
          this.$swal('Já existe um registro com este código: ID ' + response + '')
          this.adicionou = false
          return false
        }, (response) => {
          this.loading = true
          this.inserir('pro_Maodeobra', {resource: [ {mao_name: this.maoName, mao_funcao: this.maoFuncao, 
          mao_codigo: this.maoCodigo, mao_salario: this.maoSalario, mao_tipo: 'mes', enc_ID: this.encargos.enc_ID, mao_active: 1} ]}).then(
            (response) => {
              this.maoName = ''
              this.maoCodigo = ''
              this.maoFuncao = ''
              this.maoSalario = 0
              this.adicionou = false
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
              this.adicionou = false
            }
          )
        }
      )
    },
    editar: function (index, noreload) {
      // Verifica se já existe
      this.campo('pro_Maodeobra', 'mao_ID', '(mao_codigo = ' + this.mao[index].mao_codigo + ') AND (mao_ID != ' + this.mao[index].mao_ID + ')').then(
        (response) => {
          this.$swal('Já existe um registro com este código: ID ' + response + '')
          return false
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Maodeobra', {resource: [ {mao_name: this.mao[index].mao_name, 
          mao_funcao: this.mao[index].mao_funcao, mao_codigo: this.mao[index].mao_codigo, mao_salario: this.mao[index].mao_salario, mao_tipo: this.mao[index].mao_tipo,
          mao_active: this.mao[index].mao_active, enc_ID: this.mao[index].enc_ID, mao_vale1: this.mao[index].mao_vale1, mao_vale2: this.mao[index].mao_vale2, mao_vale3: this.mao[index].mao_vale3,
          mao_vale4: this.mao[index].mao_vale4, mao_vale5: this.mao[index].mao_vale5, 
          mao_total: this.mao[index].mao_total, mao_qt: this.mao[index].mao_qt, 
          mao_ocio: this.mao[index].mao_ocio, mao_custohora: this.mao[index].mao_custohora, mao_customin: this.mao[index].mao_customin} ]}, 'mao_ID = ' + this.mao[index].mao_ID, '', '1').then(
            (response) => {
              if (!noreload) {
                this.recarregar()
              }
              this.loading = false
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    calculaTotal: function(salva, x) {
      var encargo = 0
      var s = []
      for (var n = 0; n < this.mao.length; n++) {
        encargo = this.maoval[this.mao[n].enc_ID]
        if (this.mao[n].mao_tipo === 'hora') {
          encargo = this.maovalh[this.mao[n].enc_ID]
        }
        if (this.mao[n].mao_tipo === 'zero') {
          encargo = 0
        }
        // Encargos
        this.mao[n].mao_enc_total = (this.mao[n].mao_salario === 0 ? 0 : (((this.mao[n].mao_salario * (100 + encargo)) / 100) - this.mao[n].mao_salario))
        // Total
        this.mao[n].mao_total = (this.mao[n].mao_salario + this.mao[n].mao_enc_total + this.mao[n].mao_vale1 + this.mao[n].mao_vale2 + this.mao[n].mao_vale3 + this.mao[n].mao_vale4 + this.mao[n].mao_vale5)
        // Custo hora
        this.mao[n].mao_custohora = (this.mao[n].mao_total === 0 ? 0 : ((this.mao[n].mao_total / this.mao[n].mao_qt / (100 - this.mao[n].mao_ocio)) * 100))
        // Custo minuto
        this.mao[n].mao_customin = this.mao[n].mao_custohora / 60
        // Salva tudo
        if (salva) {
          s.push(this.atualizar('pro_Maodeobra', {resource: [ {mao_name: this.mao[index].mao_name, 
          mao_funcao: this.mao[index].mao_funcao, mao_codigo: this.mao[index].mao_codigo, mao_salario: this.mao[index].mao_salario, mao_tipo: this.mao[index].mao_tipo,
          mao_active: this.mao[index].mao_active, enc_ID: this.mao[index].enc_ID, mao_vale1: this.mao[index].mao_vale1, mao_vale2: this.mao[index].mao_vale2, mao_vale3: this.mao[index].mao_vale3,
          mao_vale4: this.mao[index].mao_vale4, mao_vale5: this.mao[index].mao_vale5, 
          mao_total: this.mao[index].mao_total, mao_qt: this.mao[index].mao_qt, 
          mao_ocio: this.mao[index].mao_ocio, mao_custohora: this.mao[index].mao_custohora, mao_customin: this.mao[index].mao_customin} ]}, 'mao_ID = ' + this.mao[index].mao_ID))
        }
      }
      if (x >= 0) {
        console.log(x)
        this.editar(x, true)
      }
      if (salva) {
        Promise.all(s).then(
          (k) => {},
          (k) => {}
        )
      }
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>